@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
    margin: 0;
    // Roboto
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    // @apply bg-primary;
    background: linear-gradient(135deg, rgb(17 24 39) 0%, rgb(12 74 110) 100%);
    height: 100vh;
    width: 100vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    @apply bg-tertiary;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        @apply bg-secondary;
    }

    & > svg {
        transition: 200ms;
    }
}

input[type='text'] {
    @apply text-black;
}

// Scroll bars
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb {
    @apply bg-tertiary;
    border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mainContainer {
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    padding: 0 10%;
    gap: 16px;
}

.queue-display {
    min-height: 600px;
    max-height: 800px;
    min-width: 300px;
    padding: 8px;
    display: flex;
    flex-direction: column;

    overflow: auto;
}

.song-display {
    padding: 8px;
    display: flex;
    max-width: 400px;
    gap: 8px;
}

.thumbnail {
    max-height: 168px;
    max-width: 168px;
    @apply rounded-md;
}

.current-song-display {
}

.player-thumbnail {
    width: 1280px;
    height: 640px;

    // crop
    object-fit: cover;
}

.button-bar {
    display: flex;
    flex-direction: row;
    place-content: right;
    justify-content: right;
    gap: 32px;
}

.favorites-display {
    // margin: auto;
    min-height: 600px;
    max-height: 800px;
    min-width: 300px;
    // @apply bg-secondary rounded-md;
    padding: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    overflow: auto;
}

.search-input {
}

.search-form {
    width: 100%;
    justify-content: center;
    display: flex;

    & input {
        color: white;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 1rem 0 0 1rem;
        padding: 0 16px;
        width: 25%;
    }

    & button {
        border-radius: 0 1rem 1rem 0;
    }
}

.status-indicator {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
}

.history-list {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    overflow: auto;
}

.history-item {
    cursor: pointer;
}

.vertical {
    flex-direction: column;
    max-width: 170px;
}

.horizontal {
    flex-direction: row;
}

.history {
    padding: 0 10%;
    // max-width: 100%;
}

.context-menu {
    position: fixed;
    width: 300px;
    background: white;
    border-radius: 10px;
    color: black;

    & .context-menu-menu {
        padding: 10px;
    }
}

.context-menu-content {
    & .context-menu-item {
        list-style: none;
        // font-size: 32px;
        height: 50px;

        display: flex;
        padding: 0 5px 0 10px;
        align-items: center;
        cursor: pointer;

        border-radius: 5px;
        margin-bottom: 2px;

        &:hover {
            background: #f2f2f2;
        }

        & span {
            font-size: 19px;
            margin-left: 8px;
        }
    }
}

.context-menu-item {
}
